<template>
  <div
    class="absolute text-xs font-InterMedium w-full text-center"
    :style="{ bottom: `${absoluteBottom}rem`, color: `${textColor}` }"
  >
    © 2023 Texperia Techsolutions Pvt Ltd. All rights reserved
  </div>
</template>

<script>
export default {
  name: "Copyright",
  props: {
    absoluteBottom: {
      type: String,
      required: false,
      default: () => "1.5",
    },
    textColor: {
      type: String,
      required: false,
      default: () => "#000",
    },
  },
  methods: {},
};
</script>

<style scoped></style>